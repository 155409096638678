#bubble-listing {
  max-width: 100% !important;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0 !important;
  background-color: $accent-color;

  h2 {
    color: $main-color;
    font-size: 50px;
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;

    li {
      min-width: fit-content !important;
      padding: 5px 20px;
      border-radius: 20px;
      margin-top: 6px;
      margin-bottom: 5px;
      background-color: $main-color;
      color: $accent-color;
      hyphens: initial;
      list-style-type: none;
      text-align: center;
    }
  }
}
