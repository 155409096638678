#referenz-teaser {
  max-width: 100% !important;
  padding-top: 70px;
  padding-bottom: 70px;
  margin: 0 !important;
  background-color: $accent-color;

  h2.heading {
    margin-bottom: 50px;
    color: $main-color;
    font-size: 50px;
    text-align: center;
  }

  #referenz-items {
    max-width: 80% !important;
    .column-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .column-blocks-wrapper {
        //display: flex;
        width: 30% !important;
        //flex-direction: column;
        //justify-content: center;
        padding: 40px;
        border-radius: 20px;
        margin: 10px;
        background-color: $main-color;
        text-align: center;
        //color: $accent-color !important;
        //font-size: 28px;
        //hyphens: auto;
        //line-height: 35px;

        .blocks-group-wrapper {
          .block.teaser {
            margin-bottom: 5px !important;
          }

          .block.__button {
            display: flex;
            justify-content: center;

            .button {
              margin: 0;
            }
          }
        }

        .teaser-item.default {
          padding-bottom: 5px !important;
          border-bottom: none !important;
          .content {
            .headline {
              position: absolute;
              z-index: 100;
              top: -53px;
              width: fit-content;
              min-width: 60px;
              padding: 10px 20px;
              border: $accent-color solid 1px;
              border-radius: 25px;
              background-color: $main-color;
              color: $accent-color;
              font-family: $font-family-bogart;
              font-size: 19px;
              font-weight: 100;
              text-align: center;
              text-transform: initial;
            }

            .headline:before {
              position: absolute;
              z-index: 0;
              right: 74%;
              bottom: -21px;
              display: block;
              width: 0;
              border-width: 0 21px 21px 0;
              border-style: solid;
              border-color: transparent $accent-color transparent transparent;
              margin-left: -10px;
              content: '';
            }

            .headline:after {
              position: absolute;
              z-index: 1;
              right: 75%;
              bottom: -18px;
              display: block;
              width: 0;
              border-width: 0 20px 20px 0;
              border-style: solid;
              border-color: transparent $main-color transparent transparent;
              margin-left: -10px;
              content: '';
            }

            p:last-child {
              display: none;
            }

            h2 {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
