#newsletter-block {
  position: relative;
  max-width: 80% !important;
  padding-top: 150px !important;
  padding-bottom: 150px !important;

  h2 {
    font-size: 297px !important;
    font-weight: 250;
    letter-spacing: -4%;
    line-height: 220px;
    text-align: center;
  }

  .asd {
    position: absolute;
    width: fit-content;
    min-width: 80px;
    padding: 3px 20px;
    border: $accent-color solid 1px;
    border-radius: 25px;
    background: $main-color;
    color: $accent-color;
    font-family: $font-family-bogart;
    font-size: 19px;
    text-align: center;
  }
  .asd:before {
    position: absolute;
    z-index: 0;
    bottom: -22px;
    left: 58%;
    display: block;
    width: 0;
    border-width: 0 21px 21px 0;
    border-style: solid;
    border-color: transparent $accent-color transparent transparent;
    margin-left: -10px;
    content: '';
  }

  .asd:after {
    position: absolute;
    z-index: 1;
    bottom: -20px;
    left: 58%;
    display: block;
    width: 0;
    border-width: 0 20px 20px 0;
    border-style: solid;
    border-color: transparent $main-color transparent transparent;
    margin-left: -10px;
    content: '';
  }

  .asd:nth-child(2) {
    top: 0 !important;
    left: 600px !important;
  }

  .asd:nth-child(3) {
    top: 0 !important;
    left: 100px !important;
  }

  .asd:nth-child(4) {
    top: 50px !important;
    left: 1000px !important;
  }

  .asd:nth-child(5) {
    top: 50px !important;
    left: 250px !important;
  }

  .asd:nth-child(6) {
    top: 150px !important;
    left: 800px !important;
  }

  .asd:nth-child(7) {
    top: 120px !important;
    left: 100px !important;
  }

  .asd:nth-child(8) {
    top: 150px !important;
    left: 1200px !important;
  }

  .asd:nth-child(9) {
    top: 100px !important;
    left: 400px !important;
  }

  .asd:nth-child(10) {
    top: 8px !important;
    left: 1300px !important;
  }

  .asd:nth-child(11) {
    top: 8px !important;
    left: 400px !important;
  }
}
