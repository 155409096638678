@import 'variables';
@import 'blog_filter_colors';
@import 'forschung_filter_colors';

.x-reference-filter-container {
  margin-bottom: 40px;

  .x-filter-buttons-wrapper {
    display: flex;
    width: 40%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px auto;

    div {
      margin: 15px 5px;

      button {
        padding: 6px 24px;
        border: none;
        border-radius: 20px;
        background-color: $accent-color;
        color: $main-color;
        font-family: $font-family-bogart;
        font-size: 16px;
        outline: 1px solid $main-color;
        text-transform: capitalize;
      }

      button:hover {
        background-color: $main-color;
        color: $accent-color;
        outline: 1px solid $main-color;
      }

      button.x-active-filter {
        padding: 6px 24px;
        border: none;
        border-radius: 20px;
        background-color: $main-color;
        color: $accent-color;
        font-size: 16px;
        outline: 1px solid $main-color;
        text-transform: capitalize;
      }

      button.x-active-filter:hover {
        background-color: $main-color;
        color: $accent-color;
      }
    }
  }

  .x-references-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    .x-references-item {
      display: flex;
      width: 407px !important;
      flex-direction: column;
      justify-content: center;
      padding: 40px;
      border-radius: 20px;
      margin: 70px 10px 10px;
      background-color: $main-color;
      color: $accent-color !important;
      font-size: 28px;
      hyphens: auto;
      line-height: 35px;

      h3 {
        color: $accent-color !important;
        text-align: center;
      }

      .x-reference-link {
        width: fit-content;
        padding: 5px 20px;
        border-radius: 20px;
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
        color: $accent-color;
        font-family: $font-family-bogart-regular;
        font-size: 14px;
        outline: 2px dotted $accent-color;
        text-align: center;
      }

      .x-reference-link:hover {
        background-color: $accent-color;
        color: $main-color;
        outline: 1px solid $accent-color;
      }
    }
  }

  .x-reference-filter-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .pagination {
      background-color: $accent-color;

      .item {
        color: $main-color !important;
      }

      .item.active {
        color: $main-color !important;
        font-weight: 900;
      }
    }
  }
}

@media only screen and (max-width: 924px) {
  .x-reference-filter-container {
    .x-filter-buttons-wrapper {
      width: 70%;
    }

    .x-references-container {
      width: 90%;

      .x-references-item {
        margin-top: 50px;
      }
    }

    .x-reference-filter-pagination {
      width: 90%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
