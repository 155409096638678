#scroll-section {
  max-width: 90% !important;
  margin-bottom: 150px;
  .heading-wrapper {
    h2 {
      font-size: 50px;
      text-align: center;
    }
  }

  .blocks-group-wrapper:first-child {
    > .block {
      position: sticky;
      top: 150px;
      height: 100px;
    }

    #boxes-container {
      .column-grid {
        .column-blocks-wrapper {
          .blocks-group-wrapper {
            .columns-view {
              width: 350px !important;
              height: 350px !important;
              padding: 8px 30px;
              border-radius: 20px;
              margin: 5px;

              .figure {
                margin-top: 20px;
                margin-bottom: 20px;
                img {
                  width: 150px !important;
                }
              }
            }

            #box-one,
            #box-two,
            #box-three,
            #box-four {
              position: sticky;
              top: 300px;
              width: 25vw;
              height: 200px;

              h3 {
                font-weight: 900;
              }
            }

            #box-one {
              margin-left: 0;
              background-color: $accent-color;
              transform: rotate(-2deg);

              h3 {
                color: $main-color;
              }

              p {
                color: $main-color;
              }
            }

            #box-two {
              margin-left: 25%;
              background-color: $main-color;
              outline: 1px solid $accent-color;
              transform: rotate(2deg);
            }

            #box-three {
              margin-left: 50%;
              background-color: $accent-color;
              transform: rotate(-2deg);

              h3 {
                color: $main-color;
              }

              p {
                color: $main-color;
              }
            }

            #box-four {
              margin-left: 75%;
              background-color: $main-color;
              outline: 1px solid $accent-color;
              transform: rotate(2deg);
            }
          }
        }
      }
    }
  }
}
