#technologie-block {
  max-width: 100% !important;
  padding-top: 80px;
  padding-bottom: 200px;
  margin: 0 !important;
  background-color: $main-color;

  .blocks-group-wrapper {
    max-width: 100% !important;
    padding-bottom: 10px !important;
    .block {
      .heading-wrapper {
        max-width: 100% !important;

        h2 {
          font-size: 50px;
          text-align: center;
        }
      }
    }

    #technologie-container {
      position: sticky;
      top: 150px;

      #technologie-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .column-grid {
          margin-top: 0;
          margin-bottom: 0;

          .column-blocks-wrapper {
            width: 305px !important;
            padding-top: 20px !important;
            padding-right: 15px;
            padding-bottom: 20px !important;
            padding-left: 15px;
            border-radius: 5px;
            margin: 10px;
            background-color: $accent-color;

            .blocks-group-wrapper {
              p {
                color: $main-color;
              }

              p:first-child {
                margin-bottom: 5px;
                font-size: 16px !important;
                font-weight: 900 !important;
              }

              p:last-child {
                margin-top: 30px;
                margin-bottom: 22px;
                font-size: 42px !important;
              }

              .separator {
                margin-top: 5px;
                margin-bottom: 5px;

                .line:after {
                  border-top: 1px solid $main-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
