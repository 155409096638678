@import 'variables';
@import 'default_nav_colors';
@import 'career_nav_colors';
@import 'research_nav_colors';

.view-editview.section-edit {
  #x-navigation {
    position: relative;
    top: 0;
  }
}

#x-navigation {
  position: fixed;
  z-index: 1000;
  top: 0;
  min-height: $nav-height;
  background-color: $accent-color;
  color: $main-color;
  font-family: $font-family-bogart !important;

  .x-nav-logo {
    display: flex;
    align-items: center;
  }

  .x-nav-wrapper {
    height: 100%;

    .nav-items-wrapper {
      height: 100%;
    }
  }

  .language-selector {
    margin: 0;
  }

  .x-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .x-nav-item-social-media-link {
      display: none;
      /*width: 30px; Temporär f. die Veröffentlichung der Karriereseite
      height: 30px;
      border-radius: 100%;
      margin-left: $nav-padding-texts;
      list-style: none;*/
    }

    .x-nav-item-language-selector {
      display: none;
      /*margin-left: $nav-padding-texts; Temporär f. die Veröffentlichung der Karriereseite
      list-style: none;*/
    }

    .x-nav-item {
      display: flex;
      align-items: center;

      .x-nav-item-link {
        display: flex;
        height: 100%;
        align-items: center;
      }

      span {
        font-family: $font-family-bogart !important;
      }

      &:nth-child(1) {
        &:hover {
          .x-nav-item-expanded-box {
            display: flex;
          }
        }
      }

      &:nth-child(2) {
        &:hover {
          .x-nav-item-expanded-box {
            display: flex;
          }
        }
      }
    }

    .x-nav-items-wrapper-left {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: start;
      padding: 0;
      margin: 0 0 0 $nav-margin-x;

      .x-nav-item-with-childs {
        height: $nav-height;
        padding-right: $nav-padding-texts;
        line-height: $nav-height;
        list-style: none;
        text-align: center;
      }
    }

    .x-nav-items-wrapper-right {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: end;
      padding: 0;
      margin: 0 $nav-margin-x 0 0;

      .x-nav-item-with-childs {
        height: $nav-height;
        padding-left: $nav-padding-texts;
        line-height: $nav-height;
        list-style: none;
        text-align: center;
      }
    }
  }

  .x-block-expand {
    display: none !important;
  }

  .x-nav-item-expanded-box {
    position: absolute;
    z-index: 999;
    top: $nav-height;
    left: 0;
    display: none;
    width: 100vw;
    height: $nav-expanded-height;
    padding-top: $nav-expanded-padding-y;
    padding-bottom: $nav-expanded-padding-y;
    background-color: $accent-color;

    &:hover {
      display: flex;
    }

    .x-nav-item-childs {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0;
      margin-bottom: 0;
      list-style: none;
      text-align: start;

      &:first-child {
        padding-left: $nav-margin-x;
      }

      &:nth-child(2) {
        padding-right: $nav-expanded-middle-padding-x;
        padding-left: $nav-expanded-middle-padding-x;
      }

      &:nth-child(3) {
        padding: 0;
      }

      .x-nav-list-item-childs {
        padding: 0;

        li {
          display: flex;
          padding-top: $nav-expanded-list-item-space-y;
          padding-bottom: $nav-expanded-list-item-space-y;
          list-style: none;

          a {
            p {
              font-size: $nav-font-size-two;
            }

            .x-nav-right-text {
              font-size: $nav-font-size-three;
            }
          }

          &:first-child {
            margin-top: $nav-expanded-list-item-border-margin-top;
          }
        }
      }

      .x-nav-list-item-childs-right {
        li {
          font-size: $nav-font-size-three;
        }
      }

      .x-nav-image-container {
        height: 100%;
        border: 1px solid $main-color;
        border-radius: 23px;
        margin-right: $nav-margin-x;
      }
    }
  }

  /* Reference expanded menu */
  .x-nav-expanded-box-reference {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-right: $nav-margin-x;
    margin-left: $nav-margin-x;

    .x-nav-item-reference {
      display: flex;
      box-sizing: border-box;
      flex: 1 1 calc(33.333% - 1rem);
      align-items: center;
      justify-content: start;
      border-top: 1px solid transparent;

      &:hover {
        p {
          &::after {
            margin-left: 1.5rem;
            content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none"><path d="M7.1687 7.50019L6.6692 6.92669L1.3412 0.710693L-3.48592e-05 1.85769L4.83779 7.50005L-3.53525e-05 13.1424L1.3412 14.2894L6.6692 8.0734L7.1687 7.50019Z" fill="%23FFD7C8"/></svg>');
            font-size: inherit;
          }
        }
      }

      p {
        margin-left: 20px;
      }

      .x-nav-item-reference-img-placeholder {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        //background-color: $main-color;
      }
    }
  }
}

// responsive navigation
.big-header-wrapper {
  display: block;
  height: 100%;
}

.small-header-wrapper {
  display: none;
  height: $nav-height;
}

@media only screen and (max-width: 1200px) {
  .big-header-wrapper {
    display: none;
  }

  .x-responsive-dropdown-arrow {
    margin-right: 8px;
  }

  .x-expanded-nav {
    position: fixed;
    z-index: 99;
    overflow: auto;
    width: 100%;
    height: 100vh;

    .small-header-wrapper {
      height: $nav-height;
    }
  }

  .small-header-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: $nav-responsive-margin-x;
    padding-left: $nav-responsive-margin-x;

    .x-responsive-nav-references {
      display: flex;
      align-items: center;

      .x-responsive-nav-references-icon {
        width: 60px;
        height: 60px;
        border: 1px solid $main-color;
        border-radius: 50%;
        margin-right: 20px;
      }
    }

    .x-responsive-nav-item-with-dropdown,
    .x-responsive-nav-references {
      font-family: $font-family-jarkarta;
      font-size: $nav-font-size-three;
    }

    .x-responsive-navbar-footer-wrapper {
      margin-top: auto;

      .x-responsive-navbar-language-selector {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }

      .x-responsive-navbar-socials {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        div {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 5px;
          background-color: $main-color;
        }
      }

      svg {
        width: 100%;
      }
    }

    .line {
      transform-origin: center;
      transition:
        transform 0.3s ease,
        opacity 0.3s ease;
    }

    .open .top {
      transform: translateY(8.5px) rotate(45deg);
    }

    .open .middle {
      opacity: 0;
    }

    .open .bottom {
      transform: translateY(-8.5px) rotate(-45deg);
    }

    .burger-button {
      display: none;
      /*border: none; Temporär ausgeblendet
      background: none;
      cursor: pointer;*/
    }

    .x-responsive-dropdown-title {
      font-family: $font-family-jarkarta;
      font-size: $nav-font-size-two;
    }

    .x-responsive-title-expanded {
      text-shadow: 0 0 1.5px $main-color;
    }

    .small-header-dropdown-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 20px $nav-responsive-margin-x;

      .x-responsive-dropdown-button {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0;
        border: none;
        margin: 0;
        background: none;
        color: $main-color;
        cursor: pointer;
        text-align: left;

        .x-responsive-dropdown-expanded {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      .small-header-dropdown-ul {
        padding: 0;
        margin: 0;
      }

      li {
        margin-top: 15px;
        font-weight: normal;
        list-style: none;
      }

      .x-responsive-dropdown-wrapper {
        display: flex;
        justify-content: space-between;
      }
    }

    .responsive-navigation-wrapper {
      position: absolute;
      z-index: 99;
      top: calc($nav-height - 1px);
      left: 0;
      display: flex;
      width: 100%;
      min-height: calc(100vh - $nav-height);
      flex-direction: column;
      background-color: $accent-color;

      &[aria-expanded='false'] {
        display: none;
      }

      &[aria-expanded='true'] {
        display: flex;
      }
    }
  }
}
