#accordion-section {
  max-width: 80% !important;
  margin-top: 50px !important;
  margin-bottom: 100px;

  h2 {
    margin-bottom: 50px !important;
    color: $accent-color;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
  }

  .accordion-block {
    max-width: 100% !important;
    .accordion {
      position: relative;
      padding: 90px !important;
      border-top: 0;
      margin-top: 20px !important;

      //Accordion Titel
      .accordion-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        cursor: url('assets/images/cursor-plus.png'),
          url('assets/images/cursor-plus.svg'), auto;
        text-align: center;

        span {
          color: $accent-color;
          font-size: 50px !important;
        }

        svg {
          display: none;
        }
      }

      //Accordion Content
      .content {
        .blocks-group-wrapper {
          h2 {
            max-width: 100% !important;
            margin-right: 0;
            margin-bottom: 10px !important;
            margin-left: 0;
          }

          .separator {
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .table {
            border: 0 !important;
            background-color: $main-color;
            thead {
              display: none;
            }
            tbody {
              border: 0 !important;
              tr {
                border: 0 !important;
                td {
                  border: 0 !important;
                  h3 {
                    font-size: 28px;
                  }
                }

                td:first-child {
                  width: 40%;
                }
              }
            }
          }
        }
      }
    }

    .active.accordion-title {
      cursor: url('assets/images/cursor-minus.png'),
        url('assets/images/cursor-minus.svg'), auto !important;

      span {
        display: none;
      }
    }

    //Peach
    .accordion:nth-child(odd) {
      border-radius: 20px;
      background-color: $main-color;
      outline: 1px solid $accent-color;
      span {
        color: $accent-color;
      }
    }

    //Blau
    .accordion:nth-child(even) {
      border-radius: 20px;
      background-color: $accent-color;
      span {
        color: $main-color;
      }

      //Accordion Content
      .content {
        .blocks-group-wrapper {
          h2 {
            color: $main-color;
          }

          .separator {
            .line:after {
              border-top: 1px solid $main-color !important;
            }
          }

          .table {
            background-color: $accent-color;

            p {
              color: $main-color;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .accordion-block {
      .accordion {
        padding: 60px !important;
        .accordion-title {
          span {
            font-size: 30px !important;
          }
        }
      }
    }

    .accordion:nth-child(even) {
      border-radius: 20px;
      background-color: $accent-color;

      span {
        color: $main-color;
      }

      svg {
        display: block;

        path {
          fill: $main-color;
        }
      }
    }

    .accordion:nth-child(odd) {
      border-radius: 20px;
      background-color: $main-color;
      outline: 1px solid $accent-color;

      span {
        color: $accent-color;
      }

      svg {
        display: block;

        path {
          fill: $accent-color;
        }
      }
    }
  }
}
