@import 'green_footer_colors';
@import 'yellow_footer_colors';
@import 'peach_footer_colors';

#footer {
  padding-top: 33px !important;
  padding-bottom: 0px;
  margin-top: 0;
  background-color: $accent-color !important;

  h2 {
    color: $main-color !important;
  }

  h3 {
    margin-bottom: 5px;
    color: $main-color !important;
    font-family: $font-family-bogart !important;
    font-size: 28px;
    font-weight: 100 !important;
    line-height: 33.6px;
  }

  li {
    color: $main-color !important;
  }

  p {
    margin-bottom: 5px;
    color: $main-color !important;
    font-family: $font-family-jarkarta !important;
    font-size: 15px;
    line-height: 1.5;
  }

  a {
    color: $main-color;
    font-size: 14px;
    font-weight: 200 !important;
    letter-spacing: 1px;
    line-height: 19px;

    p:hover {
      font-family: $font-family-jarkarta !important;
      text-shadow:
        0 0 0.65px #ffd7c8,
        0 0 0.65px #ffd7c8;
    }
  }

  a {
    p:hover:after {
      margin-left: 5px;
      content: '>';
      font-family: $font-family-jarkarta !important;
      font-size: 10px;
    }
  }

  a:hover {
    text-shadow:
      0 0 0.65px $main-color,
      0 0 0.65px $main-color;
  }

  .footer-outer {
    max-width: 100% !important;

    //Navigation im Footer
    .footer-inner {
      max-width: 90% !important;
      margin-right: auto;
      margin-left: auto;

      .footer {
        .footer-column {
          align-items: normal;
          padding: 0px 30px 0px 30px;
          background-color: $accent-color !important;
          text-align: right;
        }

        .footer-pack-right {
          //display: flex; für die Veröffentlichung der Karriere-Seite
          display: none;

          p {
            text-align: left;
          }

          .footer-column-start {
            padding-left: 0;
          }
        }

        .footer-pack-left {
          display: flex;
          justify-content: right;

          .social-media {
            .footer-up-to-date {
              display: flex;
              justify-content: end;

              svg {
                width: 25px;
                //height: fit-content;
                margin-left: 8px;
                fill: $main-color !important;
              }
            }
          }

          .footer-column-end.adress {
            padding-right: 0;
            .find-us {
              .contact-details {
                .companies {
                  margin-bottom: 20px;
                }
              }
            }
          }

          p {
            text-align: right;
          }
        }
      }

      //Zurück nach oben
      .footer-button {
        display: flex;
        justify-content: center;
        padding-top: 1px;
        padding-bottom: 1px;
        border-radius: 25px;
        margin-right: auto;
        margin-left: auto;
        background-color: $accent-color;
        outline: 2px dotted $main-color;

        a {
          padding: 7px 25px 7px 25px;
          border: 0;
          border-radius: 25px;
          color: $main-color;
          font-family: $font-family-bogart-regular !important;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 19px;
          //outline: 2px dotted #1e374b;
          text-decoration: none;
          text-transform: uppercase;
        }
      }

      .footer {
        display: flex;
        //justify-content: space-between;
        justify-content: end;
        padding-right: 0;
        padding-left: 0;
        background-color: $accent-color !important;
      }
    }

    //Logo und Ellipsen
    .footer-svg {
      margin-bottom: -6px;
      svg:first-child {
        width: 100%;
      }
    }

    //Impressum & Datenschutz
    .footer-end {
      display: flex;
      justify-content: center;
      border-top: 1px solid $main-color;

      a {
        padding-top: 5px;
        padding-bottom: 5px;
        color: $main-color;
        font-family: $font-family-bogart-regular;
        font-size: 15px;
        font-weight: bold !important;
        text-transform: uppercase;
      }

      a:hover {
        text-shadow:
          0 0 0.65px $main-color,
          0 0 0.65px $main-color;
      }

      a:first-child {
        margin-right: 5px;
        text-align: right;
      }

      a:nth-child(2) {
        margin-left: 5px;
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 1263px) {
  #footer {
    .footer {
      display: inline-block;
      padding-top: 25px;

      p {
        font-size: 12px;
      }

      h3 {
        font-size: 18px;
      }
    }

    .footer .footer-pack-left {
      display: flex;
      flex-direction: row;
      padding-top: 25px;
    }

    /*.footer .footer-pack-right {
      display: flex;
      flex-direction: row;
      padding-top: 25px;
    }*/

    .footer .footer-pack-left {
      flex-direction: row;
    }

    .footer .footer-pack-left .social-media {
      //width: 100%;
      //padding-top: 40px;
      //padding-bottom: 40px;

      h3 {
        text-align: center;
      }

      p {
        text-align: right;
      }
    }

    .footer .footer-pack-left .adress .find-us {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .companies,
      .adress-detail {
        flex-direction: row-reverse;
        margin-bottom: 20px;
        //padding: 0px 30px 0 30px;
      }
    }

    .footer .footer-pack-left p {
      text-align: left;
    }
  }
}

@media only screen and (max-width: 916px) {
  #footer {
    .footer-outer {
      .footer-inner {
        .footer {
          flex-direction: column;

          .footer-pack-left {
            flex-direction: column-reverse;
            justify-content: center;

            .social-media {
              margin-bottom: 1rem;

              h3 {
                margin-bottom: 1rem;
              }

              .footer-up-to-date,
              p {
                justify-content: center;
                text-align: center;
              }
            }

            h3 {
              text-align: center;
            }

            .footer-column-end {
              h3 {
                margin-bottom: 20px;
              }

              .find-us {
                flex-direction: row-reverse;
                justify-content: center;

                .contact-details {
                  display: flex;
                  flex-direction: row-reverse;
                  margin-bottom: 40px;

                  .companies {
                    margin-left: 40px;
                    p {
                      text-align: left !important;
                    }
                  }

                  .adress-details {
                    margin-left: 10px;

                    p {
                      text-align: left !important;
                    }

                    a {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }

          .footer-pack-right {
            flex-direction: column-reverse;
            justify-content: center;
          }
        }
      }

      .footer-svg svg:first-child {
        width: 40% !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    .footer-pack-left {
      justify-content: space-between;

      .adress {
        .find-us {
          justify-content: space-between;
          div {
            margin-bottom: -2px;
          }
        }
      }
    }

    .footer-pack-right {
      justify-content: space-between;
    }
  }
  .footer .footer-pack-left .adress .find-us .companies {
    padding: 0;
    //margin-left: 20px;
  }

  .footer .footer-pack-left .adress .find-us .adress-details {
    padding: 0;
  }

  .footer .footer-pack-left .adress .find-us .contact-details {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .section-referenzen.contenttype-document #footer .footer-outer .footer-end a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 572px) {
  #footer
    .footer-outer
    .footer-inner
    .footer
    .footer-pack-left
    .footer-column-end.adress {
    padding-right: initial;
    padding-left: initial;
  }

  #footer
    .footer-outer
    .footer-inner
    .footer
    .footer-pack-left
    .footer-column-end
    .find-us {
    width: 100%;
  }

  #footer
    .footer-outer
    .footer-inner
    .footer
    .footer-pack-left
    .footer-column-end
    .find-us
    .contact-details
    .adress-details {
    margin-left: 0;
  }

  #footer
    .footer-outer
    .footer-inner
    .footer
    .footer-pack-left
    .footer-column-end.adress
    .find-us
    .contact-details
    .companies {
    margin-left: 20px;
  }
}
