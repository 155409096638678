#card-element {
  .heading-wrapper {
    h2 {
      font-size: 50px;
      text-align: center;
    }
  }

  #card-items {
    .column-grid {
      .column {
        padding: 30px;
        border: 1px solid $accent-color;
        border-radius: 20px;
      }

      .column:nth-child(odd) {
        background-color: $accent-color;
        h2,
        p {
          color: $main-color;
        }
      }
    }
  }
}
