@import 'variables';
@import 'custom_header';
@import 'custom_component';
@import '_default_filter_colors';
@import 'custom_footer';
@import 'light_dark_block';
@import 'sub_menu_items';
@import 'box_scrolling';
@import 'bubble_listing_component';
@import 'technologie_component';
@import 'modal-block';
@import 'cards-element';
@import 'referenz-teaser_component';
@import 'headline_with_bubbles';
@import 'accordion_section';
@import 'scrolling-images';
@import 'dark-block-headline';
@import 'scroll-section-five';

.contenttype-lrf {
  background-color: $main-color;
  h1 {
    display: none;
  }
}

.section-referenzen.contenttype-document {
  background-color: $accent-color;
  counter-reset: vorteil;
  counter-reset: technologie;
}

.section-referenzen.contenttype-referenz,
.section-referenzen.contenttype-folder {
  background-color: $main-color;
  counter-reset: vorteil;
  counter-reset: technologie;
}

.section-loesungen-produkte {
  background-color: $main-color;
  counter-reset: vorteil;
  counter-reset: technologie;

  h1 {
    margin-top: 130px;
    font-size: 100px;
    hyphens: initial;
    line-height: 90px;
    text-align: center;
  }

  h2 {
    line-height: 55px !important;
  }
}

.section-karriere,
.section-karriere-bei-x-net {
  background-color: $main-green-color;
  counter-reset: vorteil;
  counter-reset: technologie;
}

.section-ueber-uns {
  background-color: $main-green-color;
  counter-reset: vorteil;
  counter-reset: technologie;
}

.section-blog {
  background-color: $main-green-color;
  counter-reset: vorteil;
  counter-reset: technologie;
}

.section-forschung {
  background-color: $main-yellow-color;
  counter-reset: vorteil;
  counter-reset: technologie;
}

h1 {
  max-width: 80%;
  color: $accent-color;
  font-family: $font-family-bogart !important;
  font-weight: 100 !important;
  hyphens: unset;
  line-height: 110px;
}

h2 {
  color: $accent-color;
  font-family: $font-family-bogart !important;
  font-weight: 100 !important;
  hyphens: unset;
  line-height: 60px;
}

h3 {
  color: $accent-color;
  font-family: $font-family-bogart !important;
  font-size: 28px;
  font-weight: 100;
  hyphens: unset;
}

p,
span,
li {
  font-family: $font-family-bogart !important;
  font-weight: 100 !important;
  hyphens: unset;
}

button {
  font-family: $font-family-bogart-regular !important;
  font-weight: 100 !important;
}

/** Cookie Bunner **/
.openCookieBanner .dsgvo-banner .button.olive {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px $accent-color inset !important;
  color: $accent-color;
}

.openCookieBanner .dsgvo-banner .button.olive:hover {
  background-color: $main-green-color;
  box-shadow: 0px 0px 0px 2px $main-green-color inset !important;
  color: $accent-color;
}

.openCookieBanner .ui.inverted.blue.buttons .button,
.openCookieBanner .ui.inverted.blue.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px $accent-color inset !important;
  color: $accent-color;
}

.openCookieBanner .ui.inverted.blue.buttons .button:hover,
.openCookieBanner .ui.inverted.blue.button:hover {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px $accent-color inset !important;
  color: $accent-color;
}

.openCookieBanner .ui.blue.buttons .button,
.openCookieBanner .ui.blue.button {
  background-color: $accent-color;
}

/** Button **/
.block.__button .ui.button {
  padding: 7px 25px 7px 25px;
  border: 0;
  border-radius: 25px;
  color: #1e374b !important;
  font-family: $font-family-bogart-regular !important;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 19px;
  outline: 2px dotted #1e374b;
  text-decoration: none;
  text-transform: uppercase;
}

.block.__button .ui.button:hover,
.block.__button .ui.button:focus {
  background-color: $accent-color;
  color: $main-color !important;
  outline: 1px solid $accent-color;
}

/* Main-Content */
.section-loesungen-produkte {
  .ui.basic.segment {
    background-color: $main-color !important;
  }
}

.section-referenzen.contenttype-document {
  .ui.basic.segment {
    background-color: $accent-color;
  }
}

.section-referenzen.contenttype-document .ui.basic.segment h1,
.section-referenzen.contenttype-document .ui.basic.segment p {
  color: $main-color;
  text-align: center;
}

.section-referenzen.contenttype-referenz .ui.basic.segment {
  background-color: $main-color;
}

.section-referenzen.contenttype-referenz .ui.basic.segment {
  h1 {
    color: $accent-color;
    text-align: center;
  }

  p {
    color: $accent-color;
  }
}

.section-blog .ui.basic.segment {
  background-color: $main-green-color;
}

.section-forschung .ui.basic.segment {
  background-color: $main-yellow-color;
}

.section-karriere .ui.basic.segment {
  margin: 0;
  background-color: $main-green-color;
}

/* Bread Crumbs */
.breadcrumbs {
  background-color: $main-color !important;
}

/* NAVIGATION */
.header-wrapper .header .logo-nav-wrapper .logo {
  position: absolute;
  right: 50%;
  margin-left: 50%;
}

.computer.large.screen.widescreen.only {
  width: 100%;
}

.desktop-menu li:nth-child(4) {
  margin-right: 0;
  margin-left: auto;
}

.contenttype-plone-site #view div {
  background-color: $main-color;
  font-family: $font-family-jarkarta !important;
}

p {
  color: #1e374b;
}

::selection {
  color: $accent-color !important;
}

.header-wrapper {
  background-color: $main-color;
}

/** SLOGAN  **/

#slogan {
  .column-grid {
    display: flex;
    flex-direction: row;

    .column-blocks-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      .block {
        margin: 0;

        .figure {
          margin: 0;

          img {
            width: 70px !important;
            padding-bottom: 4px;
          }
        }
      }

      .blocks-group-wrapper p {
        width: fit-content !important;
        margin: 0 13px 0 0;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .block.__button .button.container {
      margin: 0 !important;
    }

    .column-blocks-wrapper:first-child {
      display: flex;
      width: fit-content !important;
      align-items: center;
      padding: 0;
    }

    .column-blocks-wrapper:nth-child(2) {
      display: flex;
      width: fit-content !important;
      align-items: center;
      padding: 0;
    }
  }
}

#slogan figure {
  margin-top: 0;
  margin-bottom: 0;
}

#slogan .__button .button .align .button {
  width: fit-content !important;
  padding: 9px;
  border: 1.5px dotted $main-color;
  border-radius: 20rem;
  color: $main-color;
  font-family: $font-family-bogart-regular;
  font-size: 14px;
  text-transform: uppercase;
  white-space: nowrap;
}

/** Referenzen **/

.section-referenzen .ui.grid.column-grid {
  margin-bottom: 1rem;
}

.section-referenzen h1 {
  max-width: 80% !important;
  margin-top: 116px;
  font-size: 100px;
  line-height: 110px;
}

.section-referenzen #customer-block {
  h2 {
    padding-top: 20px;
    color: $main-color;
    font-family: $font-family-bogart !important;
    text-align: center;
  }
  span {
    color: $main-color;
    font-family: $font-family-bogart !important;
    text-align: center;
  }

  h3 {
    color: $main-color;
  }
}

.section-referenzen .block.__button {
  max-width: initial !important;
  margin: 0 !important;
}

.section-referenzen .button.container {
  max-width: initial !important;
  margin: 0 !important;

  .align {
    display: flex;
    max-width: 90% !important;
    justify-content: end;
  }
}

.section-referenzen #kunden-vorteil-block .button.container .align {
  justify-content: left;
}

.section-referenzen #customer-block {
  border-radius: 25px;
  background-color: $accent-color;
}

.section-referenzen .separator {
  width: 100% !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.section-referenzen #customer-block,
.section-referenzen #ausgangslage-block,
.section-referenzen #loesung-block {
  max-width: 80%;
}

.section-referenzen #customer-block .separator .line::after,
.section-referenzen #loesung-block .separator .line::after {
  border-top: 1px solid $main-color;
}

.section-referenzen
  #loesung-block
  .column-blocks-wrapper
  .blocks-group-wrapper:nth-child(1) {
  padding-bottom: 0;
}

.section-referenzen
  #loesung-block
  .column-blocks-wrapper
  .blocks-group-wrapper
  .columns-view:last-child {
  margin-bottom: 40px;
}

.section-referenzen #ausgangslage-block .separator .line::after {
  border-top: 1px solid $accent-color;
}

.section-referenzen #customer-block .separator .line {
  width: 100%;
  max-width: initial;
}

.section-referenzen .der_kunde {
  padding-top: 20px;
}

.heading-wrapper h2,
.block .heading-wrapper h2:last-child {
  margin-bottom: 0px;
}

#page-document .blocks-group-wrapper.transparent:first-child {
  padding-bottom: 0;
}

.section-referenzen .blocks-group-wrapper {
  .blocks-group-wrapper {
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;

    .blocks-group-wrapper {
      max-width: initial;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

#ausgangslage-block .column-blocks-wrapper .blocks-group-wrapper:nth-child(1) {
  padding-bottom: 0;
}

.section-referenzen #ausgangslage-block {
  border: 1px solid $accent-color;
  border-radius: 25px;
  color: $accent-color;

  p {
    color: $accent-color;
  }
  h2 {
    color: $accent-color;
    text-align: center;
  }

  h3 {
    color: $accent-color;
  }
}

.section-referenzen #loesung-block {
  border-radius: 25px;
  background-color: $accent-color;

  h2 {
    padding-top: 20px;
    color: $main-color;
    text-align: center;
  }

  h3 {
    color: $main-color;
  }

  span {
    color: $main-color;
  }

  p {
    color: $main-color;
  }
}

@media only screen and (min-width: 769px) and (max-width: 940px) {
  .section-referenzen #ausgangslage-block .column-grid,
  .section-referenzen #loesung-block .column-grid {
    display: flex;
    max-width: 100% !important;
    flex-direction: column;
  }

  .section-referenzen #ausgangslage-block .column-grid .column-blocks-wrapper,
  .section-referenzen #loesung-block .column-grid .column-blocks-wrapper {
    width: 100% !important;
  }

  .section-referenzen
    #ausgangslage-block
    .column-grid
    .column-blocks-wrapper
    .blocks-group-wrapper:first-child,
  .section-referenzen
    #loesung-block
    .column-grid
    .column-blocks-wrapper
    .blocks-group-wrapper:first-child {
    max-width: 100% !important;
    margin: 20px;
  }
}

.section-referenzen #kunden-vorteil-block {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}

.section-referenzen #kunden-vorteil-block .ui div:first-child {
  margin-top: auto;
  margin-bottom: auto;
}

.section-referenzen #kunden-vorteil-block .ui div:first-child .block.__button {
  margin-top: 0px;
  margin-left: 0px;
}

.section-referenzen #kunden-vorteil-block .blocks-group-wrapper {
  margin: 0;
}

.section-referenzen #kunden-vorteil-block {
  h2 {
    font-size: 50px;
    line-height: 58px;
  }
  ol li {
    width: 412px;
    padding: 40px;
    border: 0.87px solid $accent-color;
    border-radius: 25px;
    color: $accent-color;
    font-size: 20px;
    list-style-type: none;
  }
}

.section-referenzen #kunden-vorteil-block ol li {
  margin-bottom: 10px;
  counter-increment: vorteil;
}

.section-referenzen #kunden-vorteil-block ol li:nth-child(2n) {
  margin-left: 50px;
}

.section-referenzen #kunden-vorteil-block ol li:before {
  content: counter(vorteil) ' ';
  font-size: 50px;
}

@media only screen and (max-width: 1263px) {
  .section-referenzen #kunden-vorteil-block .column-grid {
    flex-direction: column;
  }

  .section-referenzen
    #kunden-vorteil-block
    .column-grid
    .column-blocks-wrapper {
    width: 100% !important;
  }

  .section-referenzen #kunden-vorteil-block .button.container .align {
    justify-content: left;
  }
}

/*.section-referenzen #kunden-vorteil-block ol li {
  z-index: 1;
  top: 20%;
  left: 18%;
  width: 362px;
  padding: 20px;
  border: $accent-color solid 1px;
  border-radius: 10px;
  background: $main-color;
}

.section-referenzen #kunden-vorteil-block ol li:before {
  position: absolute;
  z-index: 0;
  top: 61%;
  right: -22px;
  display: block;
  width: 0;
  border-width: 21px 0 0 21px;
  border-style: solid;
  border-color: transparent transparent transparent $accent-color;
  margin-top: -10px;
  content: '';
}

.section-referenzen #kunden-vorteil-block ol li:after {
  position: absolute;
  z-index: 1;
  top: 61%;
  right: -20px;
  display: block;
  width: 0;
  border-width: 20px 0 0 20px;
  border-style: solid;
  border-color: transparent transparent transparent $main-color;
  margin-top: -10px;
  content: '';
}*/

.section-referenzen #zitat-block span {
  color: $accent-color;
  font-size: 60px;
  text-align: center;
}

#zitat-detail .column-grid .column-blocks-wrapper:nth-child(1) {
  align-content: center;
  text-align: right;
}

#zitat-detail .column-grid .column-blocks-wrapper:nth-child(2) {
  width: fit-content !important;
  align-content: center;
}

#zitat-detail .column-grid .column-blocks-wrapper:nth-child(3) {
  align-content: center;
  text-align: left;
}

#zitat-detail .column-grid .blocks-group-wrapper {
  padding: 0;
  margin: 0;

  .block {
    margin: 0;

    .figure {
      margin: 0;
    }
  }
}

.section-referenzen #zitat-block {
  margin-bottom: 50px;
}

.section-referenzen #zitat-block .blocks-group-wrapper {
  max-width: initial;
  margin-right: 0;
  margin-left: 0;

  .zitat {
    max-width: 100%;
    font-size: 50px;
    line-height: 70px;
  }

  #zitat-detail {
    .column-grid {
      display: flex;
      justify-content: center;
    }

    .block.image figure.center {
      margin-top: 0;
    }

    img {
      width: 60px !important;
      height: 60px;
      border-radius: 5px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  #zitat-block .blocks-group-wrapper .blocks-group-wrapper {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.section-referenzen #technologie-block {
  margin-bottom: 40px;

  .blocks-group-wrapper {
    width: initial;
    max-width: 80% !important;
    text-align: center;

    .heading {
      .heading-wrapper {
        max-width: 100% !important;
        margin: 0px;

        h2.heading {
          padding-bottom: 20px;
          font-size: 50px;
          text-align: center;
        }
      }
    }
    .technologien {
      max-width: 100% !important;
      color: $main-color;

      span {
        position: relative;
        padding: 40px 30px 10px 15px;
        border-radius: 5px;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        background-color: $accent-color;
        color: $main-color;
        counter-increment: technologie;
        font-size: 42px;
        line-height: 124px;
      }

      span:before {
        position: absolute;
        z-index: 0;
        top: -40px;
        right: 45%;
        display: block;
        width: 90%;
        margin-bottom: 0;
        color: $main-color;
        content: counter(technologie, decimal-leading-zero);
        font-size: 17px;
        font-weight: 900;
      }

      span:after {
        position: absolute;
        z-index: 0;
        top: 34%;
        left: 6%;
        display: block;
        width: 85%;
        border-top: 1px solid $main-color;
        margin-bottom: 0;
        content: '';
      }
    }
  }
}
.section-referenzen .slider {
  max-width: 100% !important;
  margin: 0;
  margin-top: 50px;
  margin-bottom: 50px;

  .slider-wrapper {
    .slider-slide {
      max-width: fit-content;
      .teaser-item {
        .highlight-image-wrapper img {
          width: 600px !important;
        }
        .teaser-item-title {
          display: none;
        }
      }
    }
    .slider-slide img {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.section-referenzen .block.gridBlock .block.image figure img {
  width: 100px !important;
  aspect-ratio: initial !important;
}

.section-referenzen .block.gridBlock .block.image figure:after {
  display: none;
}

.section-referenzen .block.gridBlock .grid {
  justify-content: center;

  .column {
    width: fit-content;
  }
}

.section-referenzen .block.gridBlock h2 {
  margin-bottom: 20px;
  text-align: center;
}

.section-referenzen #ansprechsperson-block .blocks-group-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.section-referenzen #ansprechsperson-block .blocks-group-wrapper div.image {
  position: relative;
}

.section-referenzen #ansprechsperson-block .blocks-group-wrapper div.image img {
  width: 305px !important;
  border-radius: 10px;
}

.section-referenzen #ansprechsperson-block .blocks-group-wrapper span,
.section-referenzen #ansprechsperson-block .blocks-group-wrapper p {
  position: absolute;
  height: fit-content;
  padding: 30px;
  border: 1px solid $accent-color;
  border-radius: 10px;
  background-color: $main-color;
  color: $accent-color;
}

.section-referenzen #ansprechsperson-block .blocks-group-wrapper p {
  top: 36%;
  left: 58%;
}

.section-referenzen #ansprechsperson-block .blocks-group-wrapper span {
  z-index: 1;
  top: 25%;
  right: 58%;
  width: 315px;
  height: fit-content;
  padding: 20px;
  border: $accent-color solid 1px;
  border-radius: 10px;
  //position: relative;
  background: $main-color;
  color: $accent-color;
  font-size: 16px;
}

.section-referenzen #ansprechsperson-block .blocks-group-wrapper span:before {
  position: absolute;
  z-index: 0;
  top: 19%;
  right: -22px;
  display: block;
  width: 0;
  border-width: 21px 21px 0 0;
  border-style: solid;
  border-color: $accent-color transparent transparent transparent;
  margin-top: -11px;
  content: '';
}

.section-referenzen #ansprechsperson-block .blocks-group-wrapper span:after {
  position: absolute;
  z-index: 1;
  top: 19%;
  right: -19px;
  display: block;
  width: 0;
  border-width: 20px 20px 0 0;
  border-style: solid;
  border-color: $main-color transparent transparent transparent;
  margin-top: -10px;
  content: '';
}

/* Karriere */
.section-karriere.contenttype-document .ui.basic.segment h1 {
  color: $accent-color;
  hyphens: auto !important;
  text-align: center;
}

.section-karriere.contenttype-karriere h2 {
  hyphens: auto !important;
}

.section-karriere .ui.basic.segment p {
  color: $accent-color;
  font-size: 20px;
  letter-spacing: -2%;
  line-height: 130%;
  text-align: center;
}

.section-karriere #wir-leben-abschnitt {
  max-width: 90%;
  h3 {
    max-width: 100%;
    color: $accent-color;
  }

  ul {
    max-width: 90%;
    li {
      color: $accent-color;
    }
  }

  .column .column-blocks-wrapper:first-child {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.section-karriere #page-document .blocks-group-wrapper {
  background-color: $main-green-color;
}

.section-karriere #page-document h1.documentFirstHeading {
  margin-top: 110px;
  margin-bottom: 30px;
  font-size: 100px;
}

.section-karriere.contenttype-karriere #page-document a {
  padding: 7px 25px 7px 25px;
  border-radius: 25px;
  color: $accent-color !important;
  font-family: $font-family-bogart-regular;
  font-size: 14px;
  letter-spacing: 1px;
  outline: 2px dotted $accent-color;
  text-decoration: none;
  text-transform: uppercase;
}

.section-karriere.contenttype-karriere #page-document a:hover,
.section-karriere.contenttype-karriere #page-document a:focus {
  background-color: $accent-color;
  color: $main-green-color !important;
  outline: 1px solid $accent-color;
}

.section-karriere.contenttype-karriere #jetzt-bewerben .column-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .column-blocks-wrapper {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.section-karriere.contenttype-document #page-document {
  .blocks-group-wrapper:first-child .block {
    max-width: 90% !important;
    margin-bottom: 0px;

    .heading-wrapper {
      margin: 0;
    }
  }

  p {
    padding-top: 40px;
    text-align: center !important;

    a {
      padding: 7px 25px 7px 25px;
      border-radius: 25px;
      color: $accent-color !important;
      font-family: $font-family-bogart-regular;
      font-size: 14px;
      letter-spacing: 1px;
      outline: 2px dotted $accent-color;
      text-decoration: none;
      text-transform: uppercase;
    }

    a:hover,
    a:focus {
      background-color: $accent-color;
      color: $main-green-color !important;
      outline: 1px solid $accent-color;
    }
  }

  .figure {
    max-width: 100% !important;
  }

  img {
    border-radius: 15px;
  }

  .button:hover {
    color: $main-green-color;
  }

  .block.__button .button.container {
    //margin-top: 50px;
    //margin: 0;
  }

  p {
    text-align: left;
  }

  h2 {
    font-size: 50px;
  }
}

.section-karriere.contenttype-document #opene-jobs {
  max-width: 90%;
  border: 1px solid $accent-color;
  border-radius: 15px;
  margin-top: 20px;

  .column-grid {
    .column:not(:last-child) {
      border-right: 1px solid $accent-color;
      //margin-top: auto;
    }
  }

  p {
    font-size: 16px;
    text-align: left !important;
  }

  .column-blocks-wrapper:nth-child(1) {
    display: flex;
  }

  .column-blocks-wrapper .blocks-group-wrapper:first-child {
    display: flex;
    align-items: center;
    padding: 0;
    margin-right: 20px;
    margin-left: 20px;
  }

  .column-blocks-wrapper:nth-child(2) {
    display: flex;
    align-items: end;
  }

  .column-blocks-wrapper:nth-child(3) {
    display: flex;
    flex-direction: column-reverse;

    p {
      margin-left: 20px;
    }

    .button {
      margin: 0;
    }

    .button:hover,
    .button:focus {
      border-radius: 25px;
      color: $main-green-color !important;
      outline: 1px solid $accent-color;
    }
  }

  .column-blocks-wrapper .blocks-group-wrapper:last-child {
    margin-bottom: 0 !important;

    p {
      margin-bottom: 0;
    }
  }

  .column-blocks-wrapper:last-child .blocks-group-wrapper:first-child {
    display: flex;
    max-width: 100%;
    flex-direction: row;
    align-items: end;
    margin: 0;
  }

  h3 {
    color: $accent-color;
    font-size: 28px;
    hyphens: auto;

    a {
      color: $accent-color;
      font-family: $font-family-bogart-regular;
      font-size: 28px;
    }
  }

  div:nth-child(2) .blocks-group-wrapper {
    //margin-top: 80%;
    padding-bottom: 0;
  }
}

.section-karriere.contenttype-document #initivaitvbewerbung {
  .column-grid .column-blocks-wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;

    .blocks-group-wrapper {
      display: flex;
      max-width: 30%;
      flex-direction: column;
      margin: 0;
    }

    p {
      padding-top: 0;
      margin-right: auto;
      margin-left: auto;
      text-align: left !important;
    }

    p:nth-child(2) {
      padding-top: 0;
      margin-right: auto;
      margin-left: 0;
      text-align: left !important;
    }

    .block {
      margin-left: 0;

      .button {
        margin: 0;
      }

      .button:hover,
      .button:focus {
        border-radius: 25px;
        color: $main-green-color !important;
        outline: 1px solid $accent-color;
      }
    }
  }
}

.section-karriere.contenttype-document .bubble-element {
  position: relative;
  margin-top: 150px;
  margin-bottom: 40px;

  h2 {
    font-size: 200px !important;
    font-weight: 250;
    letter-spacing: -4%;
    line-height: 220px;
    text-align: center;
  }

  div {
    position: absolute;
    width: fit-content;
    min-width: 60px;
    padding: 3px 20px;
    border: $accent-color solid 1px;
    border-radius: 25px;
    background: $main-green-color;
    color: $accent-color;
    font-family: $font-family-bogart;
    font-size: 20px;
    text-align: center;
  }
  div:before {
    position: absolute;
    z-index: 0;
    bottom: -22px;
    left: 75%;
    display: block;
    width: 0;
    border-width: 0 21px 21px 0;
    border-style: solid;
    border-color: transparent $accent-color transparent transparent;
    margin-left: -10px;
    content: '';
  }

  div:after {
    position: absolute;
    z-index: 1;
    bottom: -19px;
    left: 75%;
    display: block;
    width: 0;
    border-width: 0 20px 20px 0;
    border-style: solid;
    border-color: transparent $main-green-color transparent transparent;
    margin-left: -10px;
    content: '';
  }
}

.section-karriere.contenttype-karriere #bilder-gallerie .blocks-group-wrapper {
  margin-bottom: 0 !important;
}

.section-karriere.contenttype-karriere
  #bilder-gallerie
  .blocks-group-wrapper
  .block {
  display: flex;
  justify-content: space-between;
}

.section-karriere.contenttype-karriere #bilder-gallerie .figure {
  max-width: 100% !important;
  margin: 10px 20px 10px 0 !important;
}

.section-karriere.contenttype-document
  .x-text-box.bubble-element
  .asd:nth-child(2) {
  top: 0 !important;
  left: 50px !important;
}

.section-karriere.contenttype-document
  .x-text-box.bubble-element
  .asd:nth-child(3) {
  top: 0 !important;
  left: 700px !important;
}

.section-karriere.contenttype-document
  .x-text-box.bubble-element
  .asd:nth-child(4) {
  top: 100px !important;
  left: 200px !important;
}

.section-karriere.contenttype-document
  .x-text-box.bubble-element
  .asd:nth-child(5) {
  top: 100px !important;
  left: 800px !important;
}

.section-karriere.contenttype-document
  .x-text-box.bubble-element
  .asd:nth-child(6) {
  top: 100px !important;
  left: 1200px !important;
}

.section-karriere.contenttype-document
  .x-text-box.bubble-element
  .asd:nth-child(7) {
  top: 0px !important;
  left: 1000px !important;
}

.section-karriere.contenttype-document
  .x-text-box.bubble-element
  .asd:nth-child(8) {
  top: 120px !important;
  left: 370px !important;
}

.section-karriere.contenttype-document
  .x-text-box.bubble-element
  .asd:nth-child(9) {
  top: 0px !important;
  left: 400px !important;
}

.section-karriere.contenttype-document #benefits {
  margin-top: 112px;

  .columns-view {
    display: flex;
    justify-content: center;

    .column-grid {
      display: flex;
      width: 70%;
      flex-wrap: wrap;
      //margin-top: 50px;

      .column-blocks-wrapper {
        //margin-top: 100px;
        display: flex;
        justify-content: center;
        padding: 50px;

        p {
          width: max-content;
          padding-top: 0 !important;
          text-align: center !important;
        }

        .blocks-group-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0;

          #darf-es-noch-etwas-mehr-sein {
            max-width: 80% !important;
            line-height: normal;
          }

          .columns-view {
            max-width: 80% !important;
          }

          .block {
            max-width: initial !important;

            figure {
              width: fit-content !important;
              margin: 0 0 20px 0;

              img {
                width: 120px !important;
              }
            }
          }
        }
      }
    }
  }
}

.section-karriere.contenttype-document #benefits .heading-wrapper {
  max-width: 100% !important;
}

.section-karriere.contenttype-document #benefits #darf-es-noch-etwas-mehr-sein {
  max-width: 80% !important;
  margin-bottom: 50px !important;
  text-align: center;
}

/*.section-karriere
  #benefits
  .columns-view
  .column-grid
  .column-blocks-wrapper
  .blocks-group-wrapper
  p {
  position: absolute;
  width: fit-content;
  min-width: 60px;
  padding: 8px;
  border: $accent-color solid 1px;
  border-radius: 10px;
  background: $main-green-color;
  color: $accent-color;
  font-family: $font-family-bogart;
  font-size: 20px;
  text-align: center;
}

.section-karriere
  #benefits
  .columns-view
  .column-grid
  .column-blocks-wrapper
  .blocks-group-wrapper
  p:before {
  position: absolute;
  z-index: 0;
  bottom: -22px;
  left: 75%;
  display: block;
  width: 0;
  border-width: 0 21px 21px 0;
  border-style: solid;
  border-color: transparent $accent-color transparent transparent;
  margin-left: -10px;
  content: '';
}

.section-karriere
  #benefits
  .columns-view
  .column-grid
  .column-blocks-wrapper
  .blocks-group-wrapper
  p:after {
  position: absolute;
  z-index: 1;
  bottom: -19px;
  left: 75%;
  display: block;
  width: 0;
  border-width: 0 20px 20px 0;
  border-style: solid;
  border-color: transparent $main-green-color transparent transparent;
  margin-left: -10px;
  content: '';
}*/

/*.section-karriere.section-jobs .columns-view {
  max-width: 80% !important;

  .image {
    margin: 0 !important;
  }
}

.section-karriere.section-jobs {
  .documentFirstHeading {
    display: none;
  }

  h2 {
    max-width: 80% !important;
  }

  #hard-fakts {
    max-width: 80% !important;

    .image {
      margin: 20px !important;
    }

    .blocks-group-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 20px !important;

      .block .figure {
        width: 197px;
      }

      img {
        position: relative;
      }

      figcaption {
        position: absolute;

        p {
          margin-left: 10px;
          color: $main-green-color;
          font-size: 1.2rem;
          word-break: break-all;
        }
      }
    }
  }

  #dark-block {
    width: 80% !important;

    .column-grid .blocks-group-wrapper {
      border-radius: 25px;
      background-color: $accent-color;

      h2 {
        max-width: 80% !important;
        padding-top: 40px;
        color: $main-green-color;
        font-size: 2rem;
        text-align: center;
      }

      .separator {
        max-width: 80% !important;
        margin-top: 0px;
        margin-bottom: 20px;

        .line::after {
          border-top: 1px solid $main-green-color;
        }
      }

      p {
        max-width: 80% !important;
        color: $main-green-color;
        text-align: center;
      }
    }
  }

  #light-block {
    width: 80% !important;

    .column-grid .blocks-group-wrapper {
      border: 1px solid $accent-color;
      //background-color: $accent-color;
      border-radius: 25px;

      h2 {
        max-width: 80% !important;
        padding-top: 40px;
        color: $accent-color;
        font-size: 2rem;
        text-align: center;
      }

      .separator {
        max-width: 80% !important;
        margin-top: 0px;
        margin-bottom: 20px;

        .line::after {
          border-top: 1px solid $accent-color;
        }
      }

      p {
        max-width: 80% !important;
        color: $accent-color;
        text-align: center;
      }
    }
  }

  .button .button.noLink {
    border: 1px dotted $main-green-color;
    color: $main-green-color;
  }

  .button.container {
    margin-top: 20px !important;
    margin-bottom: 0;

    .button.noLink:hover {
      border: 1px dotted $accent-color;
      background: $main-green-color;
      color: $accent-color !important;
    }
  }
}*/

.section-karriere.contenttype-karriere .columns-view {
  max-width: 80% !important;

  .image {
    margin: 0 !important;
  }

  img {
    border-radius: 25px;
  }
}

.section-karriere.contenttype-karriere {
  .documentFirstHeading {
    /*font-size: 40px;
    line-height: 110px;
    text-align: left !important;
    max-width: 80%;*/
    display: none;
  }

  .documentDescription {
    max-width: 50% !important;
    padding-bottom: 20px;
  }

  .heading-wrapper {
    max-width: 100% !important;

    h2 {
      max-width: 80% !important;
      font-size: 3rem;
      line-height: 53px;
    }
  }

  .block.heading {
    max-width: 80% !important;
    margin-bottom: 0;
  }

  #hard-fakts {
    max-width: 80% !important;

    .image {
      margin: 20px !important;
      margin-left: 0 !important;
    }

    .blocks-group-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 20px !important;

      .block .figure {
        width: 197px;
        margin-top: 0;
        //margin-bottom: 0;
      }

      img {
        position: relative;
      }

      figcaption {
        position: absolute;

        p {
          margin-left: 10px;
          color: $main-green-color;
          font-size: 1.2rem;
          word-break: break-all;
        }
      }
    }
  }

  #dark-block {
    width: 80% !important;
    color: $main-green-color;

    .column-grid .blocks-group-wrapper {
      padding-right: 50px;
      padding-bottom: 50px !important;
      padding-left: 50px;
      border-radius: 25px;
      background-color: $accent-color;

      h2 {
        max-width: 100% !important;
        padding-top: 40px;
        color: $main-green-color;
        font-size: 2rem;
        text-align: center;
      }

      a {
        padding: 10px;
        border: 1px dotted $main-green-color;
        border-radius: 25px;
        color: $main-green-color !important;
        font-family: $font-family-bogart-regular;
        font-size: 14px;
        text-decoration: none;
        text-transform: uppercase;
      }

      a:hover {
        border: 1px dotted $accent-color;
        background-color: $main-green-color;
        color: $accent-color !important;
      }

      .separator {
        max-width: 80% !important;
        margin-top: 0px;
        margin-bottom: 20px;

        .line::after {
          border-top: 1px solid $main-green-color;
        }
      }

      ul {
        max-width: 50% !important;
        color: $main-green-color;
      }

      p {
        max-width: 80% !important;
        color: $main-green-color;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  #light-block {
    width: 80% !important;

    .column-grid .blocks-group-wrapper {
      padding-right: 50px;
      padding-bottom: 50px;
      padding-left: 50px;
      border: 1px solid $accent-color;
      //background-color: $accent-color;
      border-radius: 25px;

      h2 {
        max-width: 100% !important;
        padding-top: 40px;
        color: $accent-color;
        font-size: 2rem;
        text-align: center;
      }

      a {
        padding: 10px;
        border: 1px dotted $accent-color;
        border-radius: 25px;
        color: $accent-color !important;
        font-family: $font-family-bogart-regular;
        font-size: 14px;
        text-decoration: none;
        text-transform: uppercase;
      }

      a:hover {
        border: 1px dotted $main-green-color;
        background-color: $accent-color;
        color: $main-green-color !important;
      }

      .separator {
        max-width: 80% !important;
        margin-top: 0px;
        margin-bottom: 20px;

        .line::after {
          border-top: 1px solid $accent-color;
        }
      }

      ul {
        max-width: 50% !important;
        color: $accent-color;
      }

      p {
        max-width: 80% !important;
        color: $accent-color;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .button .button.noLink {
    border: 1px dotted $main-green-color;
    color: $main-green-color;
  }

  .button.container {
    margin-top: 20px !important;
    margin-bottom: 0;

    .button.noLink:hover {
      border: 1px dotted $accent-color;
      background: $main-green-color;
      color: $accent-color !important;
    }
  }
}

/*MEDIA QUERIES*/

@media only screen and (max-width: 1602px) {
  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper {
    align-items: start;
  }

  #initivaitvbewerbung {
    max-width: 90% !important;
  }
}

@media only screen and (max-width: 1346px) {
  .section-referenzen #ansprechsperson-block .blocks-group-wrapper {
    flex-direction: column;
  }
  .section-referenzen #ansprechsperson-block .blocks-group-wrapper span,
  .section-referenzen #ansprechsperson-block .blocks-group-wrapper p {
    position: relative;
    left: 0;
    justify-content: center;
  }

  .section-referenzen #ansprechsperson-block .blocks-group-wrapper span {
    width: 315px;
    height: fit-content;
    padding: 20px;
    border: $accent-color solid 1px;
    border-radius: 10px;
    margin-bottom: 10px;
    //position: relative;
    background: $main-color;
    color: $accent-color;
    font-size: 16px;
  }

  .section-referenzen #ansprechsperson-block .blocks-group-wrapper span:before {
    position: absolute;
    z-index: 0;
    top: initial;
    bottom: -22px;
    left: 19%;
    display: block;
    width: 0;
    border-width: 0 21px 21px 0;
    border-style: solid;
    border-color: transparent $accent-color transparent transparent;
    margin-left: -10px;
    content: '';
  }

  .section-referenzen #ansprechsperson-block .blocks-group-wrapper span:after {
    position: absolute;
    z-index: 1;
    top: initial;
    bottom: -19px;
    left: 19%;
    display: block;
    width: 0;
    border-width: 0 20px 20px 0;
    border-style: solid;
    border-color: transparent $main-color transparent transparent;
    margin-left: -10px;
    content: '';
  }

  .section-referenzen #ansprechsperson-block .block.image figure.center {
    margin: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .section-referenzen .button.container .align {
    max-width: 100% !important;
    justify-content: center;
  }

  .section-referenzen #customer-block .blocks-group-wrapper,
  .section-referenzen #ausgangslage-block .blocks-group-wrapper,
  .section-referenzen #loesung-block .blocks-group-wrapper {
    max-width: 80% !important;
  }
}

@media only screen and (max-width: 1298px) {
  .x-text-box.bubble-element {
    display: none;
  }

  .section-karriere #benefits {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-karriere.contenttype-document
    #page-document
    h1.documentFirstHeading {
    font-size: 5em;
    line-height: normal;
  }

  #initivaitvbewerbung {
    max-width: 90% !important;
  }

  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper {
    align-items: start;
  }

  /*.section-karriere #page-document .blocks-group-wrapper:first-child .block {
    max-width: 100% !important;
  }*/

  .section-karriere.contenttype-karriere
    #hard-fakts
    .blocks-group-wrapper
    figure {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .section-karriere.contenttype-karriere
    #dark-block
    .column-grid
    .blocks-group-wrapper
    h2,
  .section-karriere.contenttype-karriere
    #dark-block
    .column-grid
    .blocks-group-wrapper
    p {
    max-width: 100% !important;
  }

  .section-karriere.contenttype-karriere
    #light-block
    .column-grid
    .blocks-group-wrapper
    h2,
  .section-karriere.section-jobs
    #light-block
    .column-grid
    .blocks-group-wrapper
    p {
    max-width: 100% !important;
  }

  .section-karriere.contenttype-document
    #benefits
    #darf-es-noch-etwas-mehr-sein {
    max-width: 90% !important;
    line-height: normal;
  }

  .section-karriere.contenttype-document #opene-jobs .column-grid .column {
    margin-bottom: 20px;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper:last-child
    .blocks-group-wrapper:first-child {
    flex-direction: column;
    align-items: end;
  }

  .section-karriere.contenttype-document #opene-jobs .column-blocks-wrapper p {
    padding-top: 0;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper:last-child
    div {
    margin: 0;
    margin-right: 10px;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper:last-child
    p {
    margin-top: 20px;
    margin-left: 20px;
  }
  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper:last-child
    div {
    margin-left: 20px;
  }

  .section-karriere.contenttype-document #opene-jobs .column-grid .column {
    margin-bottom: 0;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper:nth-child(2)
    .blocks-group-wrapper:first-child {
    margin-left: 0;
  }

  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper
    .blocks-group-wrapper {
    max-width: 90% !important;
  }
}

@media only screen and (max-width: 916px) {
  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper:first-child {
    margin-top: 5px;
    margin-right: 0 !important;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper:last-child
    div {
    max-width: 100% !important;
    //margin-left: auto;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .section-karriere.contenttype-document
    #opene-jobs
    .column-grid
    .column:not(:last-child) {
    border: none;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper:nth-child(3) {
    margin-left: 20px;
  }

  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper
    .blocks-group-wrapper {
    max-width: 100% !important;
  }

  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper
    p
    a {
    text-align: left;
  }

  .section-karriere.contenttype-karriere .blocks {
    max-width: 90% !important;
  }
  .section-karriere.contenttype-karriere .heading-wrapper h2 {
    max-width: 100% !important;
    font-size: 2rem;
    text-align: center;
  }

  .section-karriere.contenttype-karriere .blocks-group-wrapper p {
    max-width: 80% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    text-align: justify !important;
  }

  .section-karriere.contenttype-karriere #hard-fakts .blocks-group-wrapper {
    justify-content: center;
  }

  .section-karriere.contenttype-karriere
    #hard-fakts
    .blocks-group-wrapper
    .block
    .figure {
    margin-bottom: 0;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper:last-child
    .blocks-group-wrapper:first-child {
    flex-direction: column;
    align-items: start;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper:last-child
    div {
    max-width: 100% !important;
    margin-left: auto;
  }

  .section-karriere.contenttype-document #opene-jobs .column-grid {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-grid
    .column:not(:first-child) {
    border-top: 1px solid $accent-color;
  }

  .section-karriere.contenttype-document #opene-jobs .column-grid .column {
    border-top: 1px solid $accent-color;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper:nth-child(3) {
    margin-left: 0;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper:last-child {
    margin-left: 0;
  }

  .section-karriere.contenttype-document
    #page-document
    h1.documentFirstHeading {
    font-size: 2.8em;
  }

  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper
    p {
    margin-left: 0;
  }

  .section-karriere.contenttype-karriere
    #dark-block
    .column-grid
    .blocks-group-wrapper,
  .section-karriere.contenttype-karriere
    #light-block
    .column-grid
    .blocks-group-wrapper {
    padding: 10px;
    padding-bottom: 40px;
  }

  .section-karriere.contenttype-karriere
    #dark-block
    .column-grid
    .blocks-group-wrapper
    h2,
  .section-karriere.contenttype-karriere
    #light-block
    .column-grid
    .blocks-group-wrapper
    h2 {
    font-size: 1.5rem;
  }

  .section-karriere.contenttype-karriere
    #light-block
    .column-grid
    .blocks-group-wrapper
    .separator,
  .section-karriere.contenttype-karriere
    #dark-block
    .column-grid
    .blocks-group-wrapper
    .separator {
    max-width: 100% !important;
  }

  .section-karriere.contenttype-karriere #hard-fakts .blocks-group-wrapper img {
    width: 75% !important;
  }

  .section-karriere.contenttype-karriere #hard-fakts .image {
    margin-right: 0 !important;
  }

  .section-karriere.contenttype-document
    #benefits
    .columns-view
    .column-grid
    .column-blocks-wrapper {
    padding: 20px;
    //width: fit-content !important;
  }

  .section-karriere.contenttype-document #benefits .columns-view .column-grid {
    width: 90%;
  }

  #page-document .blocks-group-wrapper.transparent:first-child {
    padding-bottom: 0px;
    margin-top: 40px;

    p {
      text-align: center !important;
    }
  }

  .section-karriere.contenttype-karriere #hard-fakts figcaption p {
    max-width: 100% !important;
    padding-left: 9px !important;
    //white-space: revert-layer;
    font-size: 0.9rem !important;
  }

  .section-karriere.contenttype-karriere #hard-fakts .block.image {
    justify-content: center;
  }

  .section-karriere.contenttype-karriere
    #hard-fakts
    .blocks-group-wrapper
    figure {
    position: relative;
    display: block;
  }
  .section-karriere.contenttype-karriere
    #hard-fakts
    .blocks-group-wrapper
    figcaption {
    position: absolute;
    top: 0;
    left: 34px;
  }

  .section-karriere.contenttype-karriere .block.heading {
    max-width: 90% !important;

    .heading-wrapper {
      margin: 0;
    }
  }

  .section-karriere.contenttype-karriere
    #light-block
    .column-grid
    .blocks-group-wrapper {
    //padding-right: 0;
    //padding-left: 0;

    .heading {
      margin-bottom: 0;
    }

    h2 {
      padding-top: 20px;
    }
  }

  .section-karriere.contenttype-karriere
    #dark-block
    .column-grid
    .blocks-group-wrapper {
    .heading {
      margin-bottom: 0;
    }

    h2 {
      padding-top: 20px;
    }
  }

  .section-karriere.contenttype-karriere
    #dark-block
    .column-grid
    .blocks-group-wrapper
    ul,
  .section-karriere.contenttype-karriere
    #light-block
    .column-grid
    .blocks-group-wrapper
    ul {
    max-width: 100% !important;
    padding-left: 15px;
  }

  .section-karriere.contenttype-karriere #bilder-gallerie .figure {
    margin-right: 0 !important;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper:last-child
    div
    .align {
    margin-left: 20px;
  }

  .section-karriere.contenttype-document
    #opene-jobs
    .column-blocks-wrapper
    .blocks-group-wrapper
    p {
    text-align: left !important;
  }

  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper
    p {
    margin-left: auto;
  }

  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper
    .blocks-group-wrapper {
    max-width: 100% !important;

    p:first-child {
      text-align: center !important;
    }

    p:nth-child(2) {
      margin-left: auto !important;
    }
  }

  .section-karriere.contenttype-document
    #initivaitvbewerbung
    .column-grid
    .column-blocks-wrapper {
    align-items: center;
  }
}
