#scroll-section-five {
  margin-bottom: 100px;
  .heading-wrapper {
    h2 {
      font-size: 50px;
      text-align: center;
    }
  }

  .blocks-group-wrapper {
    > .block {
      position: sticky;
      top: 150px;
      height: 150px;
    }

    .columns-view {
      width: 350px !important;
      height: 350px !important;
      padding: 20px 30px;
      border-radius: 20px;
      margin: 5px;
    }

    .column-grid {
      margin-top: 0;
    }

    #box-one,
    #box-two,
    #box-three,
    #box-four,
    #box-five {
      position: sticky;
      top: 300px;
      width: 25vw;
      height: 200px;

      h3 {
        font-weight: 900;
      }
    }

    #box-one {
      margin-left: 0;
      background-color: $accent-color;
      transform: rotate(-2deg);

      h3 {
        color: $main-color;
      }

      p {
        color: $main-color;
      }
    }

    #box-two {
      margin-left: 20%;
      background-color: $main-color;
      outline: 1px solid $accent-color;
      transform: rotate(2deg);
    }

    #box-three {
      margin-left: 40%;
      background-color: $accent-color;
      transform: rotate(-2deg);

      h3 {
        color: $main-color;
      }

      p {
        color: $main-color;
      }
    }

    #box-four {
      margin-left: 60%;
      background-color: $main-color;
      outline: 1px solid $accent-color;
      transform: rotate(2deg);
    }

    #box-five {
      margin-left: 80%;
      background-color: $accent-color;
      transform: rotate(-2deg);

      h3 {
        color: $main-color;
      }

      p {
        color: $main-color;
      }
    }
  }
}
