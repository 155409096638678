#modal-block {
  position: relative;
  z-index: 1000;
  max-width: 40% !important;
  border-radius: 20px;
  margin-top: 120px;
  margin-bottom: 200px;
  background-color: $main-color;
  outline: 1px solid $accent-color;

  .column-grid {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;

    .blocks-group-wrapper:first-child {
      max-width: 70%;
      margin-right: auto;
      margin-left: auto;

      .heading {
        margin-top: 20px;
        margin-bottom: 5px;
        h2 {
          font-size: 35px;
          text-align: center;
        }
      }

      p {
        margin-top: 20px;
        font-size: 21px;
        line-height: 22px;
        text-align: center;
      }

      .separator {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .__button {
        display: flex;
        justify-content: center;
        margin-bottom: 0;

        .button {
          margin-top: 10px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
