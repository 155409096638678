@import 'variables';

.section-forschung.contenttype-document {
  .x-nav-item-reference-img-placeholder {
    background-color: $main-yellow-color;
  }

  .x-nav-item-reference {
    &:hover {
      border-top: 1px solid $main-yellow-color !important;
    }
  }

  nav {
    .x-nav-item {
      a {
        span {
          color: $main-yellow-color;
        }
      }

      &:hover span {
        border-bottom: 1px solid $main-yellow-color;
        text-shadow:
          0 0 0.65px $main-yellow-color,
          0 0 0.65px $main-yellow-color;
      }

      .x-nav-item-expanded-box {
        p {
          color: $main-yellow-color;
        }

        .x-nav-item-border {
          border-top: 1px solid $main-yellow-color;
        }

        .x-nav-item-childs {
          .x-nav-item-childs-left {
            li {
              &:hover {
                &::after {
                  margin-left: 1.5rem;
                  content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none"><path d="M7.1687 7.50019L6.6692 6.92669L1.3412 0.710693L-3.48592e-05 1.85769L4.83779 7.50005L-3.53525e-05 13.1424L1.3412 14.2894L6.6692 8.0734L7.1687 7.50019Z" fill="%23dcffdc"/></svg>');
                  font-size: inherit;
                }
              }
            }

            .li-hovered {
              &::after {
                margin-left: 1.5rem;
                content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none"><path d="M7.1687 7.50019L6.6692 6.92669L1.3412 0.710693L-3.48592e-05 1.85769L4.83779 7.50005L-3.53525e-05 13.1424L1.3412 14.2894L6.6692 8.0734L7.1687 7.50019Z" fill="%23dcffdc"/></svg>');
                font-size: inherit;
              }
            }
          }

          .x-nav-list-item-childs {
            padding: 0;

            li {
              a {
                &:hover {
                  text-shadow:
                    0 0 1px $main-yellow-color,
                    0 0 1px $main-yellow-color;
                }
              }
            }
          }
        }
      }
    }

    .big-header-wrapper {
      .x-nav-items-wrapper-left {
        li {
          a {
            span {
              color: $main-yellow-color;
            }
          }
        }
      }

      .x-nav-items-wrapper-right {
        .x-nav-item-social-media-link {
          background-color: $main-yellow-color;
        }

        .language-selector {
          a {
            color: $main-yellow-color;
          }
        }
      }

      .customLogo {
        fill: $main-yellow-color;
      }
    }

    .small-header-wrapper {
      .customLogo {
        fill: $main-yellow-color;
      }

      .burger-button {
        svg {
          stroke: $main-yellow-color;
        }
      }

      .x-responsive-dropdown-button {
        color: $main-yellow-color;

        .x-responsive-dropdown-arrow {
          svg {
            fill: $main-yellow-color;
            stroke: $main-yellow-color;
          }
        }
      }

      .x-responsive-dropdown-title {
        color: $main-yellow-color;
      }

      .x-responsive-navbar-socials {
        div {
          background-color: $main-yellow-color;
        }
      }

      .x-responsive-navbar-footer-wrapper {
        .x-responsive-navbar-footer-svg {
          stroke: $main-yellow-color;
        }

        .x-responsive-navbar-language-selector {
          .language-selector {
            a {
              color: $main-yellow-color !important;
            }
          }
        }
      }

      .x-responsive-nav-item-with-dropdown {
        span {
          color: $main-yellow-color;
        }
      }
    }
  }
}
