#light-block {
  width: 80% !important;
  margin-top: 50px;
  margin-bottom: 100px;

  .column-grid .blocks-group-wrapper {
    padding-right: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    border: 1px solid $accent-color;
    border-radius: 25px;

    h2 {
      max-width: 100% !important;
      padding-top: 40px;
      color: $accent-color;
      font-size: 2rem;
      text-align: center;
    }

    a {
      padding: 10px;
      border: 1px dotted $accent-color;
      border-radius: 25px;
      color: $accent-color !important;
      font-family: $font-family-bogart-regular;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
    }

    a:hover {
      border: 1px dotted $main-color;
      background-color: $accent-color;
      color: $main-color !important;
    }

    .separator {
      max-width: 80% !important;
      margin-top: 0px;
      margin-bottom: 20px;

      .line::after {
        border-top: 1px solid $accent-color;
      }
    }

    ul {
      max-width: 50% !important;
      color: $accent-color;
    }

    p {
      max-width: 80% !important;
      color: $accent-color;
      font-size: 18px;
      text-align: center;
    }
  }
}

#dark-block {
  width: 80% !important;
  margin-top: 50px;
  margin-bottom: 100px;
  color: $main-green-color;

  .column-grid .blocks-group-wrapper {
    padding-right: 50px;
    padding-bottom: 50px !important;
    padding-left: 50px;
    border-radius: 25px;
    background-color: $accent-color;

    h2 {
      max-width: 100% !important;
      padding-top: 40px;
      color: $main-color;
      font-size: 2rem;
      text-align: center;
    }

    a {
      padding: 10px;
      border: 1px dotted $main-color;
      border-radius: 25px;
      color: $main-color !important;
      font-family: $font-family-bogart-regular;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
    }

    a:hover {
      border: 1px dotted $accent-color;
      background-color: $main-color;
      color: $accent-color !important;
    }

    .separator {
      max-width: 80% !important;
      margin-top: 0px;
      margin-bottom: 20px;

      .line::after {
        border-top: 1px solid $main-color;
      }
    }

    ul {
      max-width: 50% !important;
      color: $main-color;
    }

    p {
      max-width: 80% !important;
      color: $main-color;
      font-size: 18px;
      text-align: center;
    }
  }
}
