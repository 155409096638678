#submenu-items {
  position: relative;
  max-width: 80% !important;
  margin-top: 50px;
  margin-bottom: 50px;

  h2:first-child {
    max-width: 100% !important;
    margin: 20px 0 30px;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
  }

  .columns-view {
    .column-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .column-blocks-wrapper {
        width: 29% !important;
        height: 400px;
        padding-top: 15px;
        border-radius: 20px;
        margin: 10px;
        outline: 1px solid $accent-color;

        .blocks-group-wrapper {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          //margin-top: 60px;
          //margin-bottom: 60px !important;

          //Headline
          .block.teaser {
            height: unset !important;
            margin-bottom: 10px;
            .teaser-item {
              h2:first-child {
                margin-bottom: 5px;
                font-size: 35px !important;
                line-height: 37px;
                text-align: center;
              }
              p {
                font-size: 14px;
                font-weight: 900 !important;
                text-align: center;
                text-transform: uppercase;
              }
            }
          }

          //Hover Text
          > p {
            display: none;
          }

          //Button
          .block {
            margin-top: 20px;
            .button {
              margin: 0;
            }
          }
        }
      }

      .column-blocks-wrapper:hover {
        background-color: $accent-color;

        //Headline
        .block.teaser {
          .teaser-item {
            h2:first-child {
              color: $main-color;
              font-size: 20px !important;
              font-weight: 900 !important;
            }
            p {
              display: none;
            }
          }
        }

        //Hover Text
        p {
          display: block;
          margin-right: 30px;
          margin-left: 30px;
          color: $main-color;
          text-align: center;
        }

        //Button
        .block {
          button.button {
            color: $main-color !important;
            outline: 2px dotted $main-color !important;
          }

          button.button:hover {
            background-color: $main-color;
            color: $accent-color !important;
            outline: 1px solid $accent-color !important;
          }
        }
      }
    }
  }
}
