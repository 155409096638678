.section-forschung.contenttype-document {
  #footer {
    h2 {
      color: $main-yellow-color !important;
    }

    h3 {
      color: $main-yellow-color !important;
    }

    li {
      color: $main-yellow-color !important;
    }

    p {
      color: $main-yellow-color !important;
    }

    a {
      color: $main-yellow-color;

      p:hover {
        text-shadow:
          0 0 0.65px $main-yellow-color,
          0 0 0.65px $main-yellow-color;
      }
    }

    a:hover {
      text-shadow:
        0 0 0.65px $main-yellow-color,
        0 0 0.65px $main-yellow-color;
    }

    .footer-outer {
      //Navigation im Footer
      .footer-inner {
        .footer {
          .footer-pack-left {
            .social-media {
              .footer-up-to-date {
                svg {
                  fill: $main-yellow-color !important;
                }
              }
            }
          }
        }

        //Zurück nach oben
        .footer-button {
          background-color: $accent-color;
          outline: 2px dotted $main-yellow-color;

          a {
            color: $main-yellow-color;
            outline: 2px dotted #1e374b;
          }
        }

        .footer {
          background-color: $accent-color !important;
        }
      }

      //Logo und Ellipsen
      .footer-svg {
        path {
          fill: $main-yellow-color;
        }

        svg:first-child {
          width: 100%;
        }
      }

      //Impressum & Datenschutz
      .footer-end {
        border-top: 1px solid $main-yellow-color;

        a {
          color: $main-yellow-color;
        }

        a:hover {
          text-shadow:
            0 0 0.65px $main-yellow-color,
            0 0 0.65px $main-yellow-color;
        }
      }
    }
  }
}
