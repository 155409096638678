@import 'variables';

.section-blog {
  .x-reference-filter-container {
    .x-filter-buttons-wrapper {
      div {
        button {
          background-color: $main-green-color;
          color: $accent-color;
          outline: 1px solid $accent-color;
        }

        button:hover {
          background-color: $accent-color;
          color: $main-green-color;
          outline: 1px solid $accent-color;
        }

        button.x-active-filter {
          background-color: $accent-color;
          color: $main-green-color;
          outline: 1px solid $accent-color;
        }

        button.x-active-filter:hover {
          background-color: $accent-color;
          color: $main-green-color;
          outline: 1px solid $accent-color;
        }
      }
    }

    .x-references-container {
      .x-references-item {
        background-color: $main-green-color;
        color: $accent-color !important;
        outline: 1px solid $accent-color;

        .x-reference-link:hover {
          color: $main-green-color;
        }
      }
    }

    .x-reference-filter-pagination {
      .pagination {
        background-color: $accent-color;

        .item {
          color: $main-green-color !important;
        }

        .item.active {
          color: $main-green-color !important;
        }
      }
    }
  }
}
