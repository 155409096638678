$text-color: #1e374b;
$main-color: #ffd7c8;
$main-green-color: #dcffdc;
$main-yellow-color: #ffffdc;
$font-family-bogart: 'Bogart Alt Light';
$font-family-bogart-regular: 'Bogart Alt Regular';
$font-family-jarkarta: 'Plus Jakarta Sans';
$accent-color: #1e374b;

@font-face {
  font-family: 'Bogart Light';
  src: url('assets/fonts/Bogart-Light.woff') format('woff');
  font-weight: 0;
  font-style: normal;
}

@font-face {
  font-family: 'Bogart Regular';
  src: url('assets/fonts/Bogart-Regular.woff') format('woff');
  font-weight: 0;
  font-style: normal;
}

@font-face {
  font-family: 'Bogart Alt Light';
  src: url('assets/fonts/Bogart-Alt-Light.woff') format('woff');
  font-weight: 0;
  font-style: normal;
}

@font-face {
  font-family: 'Bogart Alt Regular';
  src: url('assets/fonts/Bogart-Alt-Regular.woff') format('woff');
  font-weight: 0;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('assets/fonts/plus-jakarta-sans-latin-200-normal.woff')
    format('woff');
  font-weight: 0;
  font-style: normal;
}

// NAV specific variables
$nav-height: 80px;
$nav-margin-x: 70px;
$nav-padding-texts: 20px;
$nav-expanded-height: 473px;
$nav-expanded-padding-y: 40px;
$nav-expanded-list-item-space-y: 15px;
$nav-expanded-list-item-font-size: 24px;
$nav-expanded-list-item-font-size-second: 20px;
$nav-expanded-list-item-border-margin-top: 20px;
$nav-expanded-middle-padding-x: 20px;

$nav-font-size-one: 18px;
$nav-font-size-two: 24px;
$nav-font-size-three: 20px;

// Responsive NAV specific variables
$nav-responsive-margin-x: 30px
